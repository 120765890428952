body {
  /*font: 14px "Century Gothic", Futura, sans-serif;*/
  margin: 20px;
}

  
label {
	color: grey;
    margin-bottom: 3px;
    margin-top: 5px;
}

h6 {
	margin-top: 5px;
	font-weight: bold;
}

input {
	border: 2px solid lightgrey;
	border-radius: 5px;
	padding-left: 5px;
}

.hiddenIcon {
	visibility: hidden;
}
  
.form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chainringSize {
	font-weight: bold;
	width: 25px;
}

.cassetteGearSize {
	font-weight: bold;
	width: 60px;
}

.effectiveWheelSize {
	text-align: center;
}

.chainringByCassetteSize {
	font-weight: bold;
	width: 80px;
}

.gearNumber {
	text-align: center;
}


.selectionTable {
	width: 100%;
}

.selectionCell {
	vertical-align: top;
}

/* Dropdown Button */
.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #3e8e41;}

/* Hide and show Results Panel */
.hideResultsPanel {
	display: none;
}

.showResultsPanel {
	/*display: none;*/
}

.calculateButton {
	padding: 10px 10px 10px 10px;
}

.popupDescription {
}

.clear { 
	clear: both; 
	height: 60px; 
}


